import React, { useEffect, useState } from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import Button from "../components/Button"
import Layout from "../components/Layout"
import Content from "../components/Content"
import styles from "./anmalan.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import SEO from "../components/Seo"
import { getPrice } from "../services/price"
import { groups, groupSize } from "../config"

export default function Registration() {
  const [hasRegistered, setHasRegistered] = useState(false)
  const [confirmedPrice, setConfirmedPrice] = useState(null)
  const [error, setError] = useState(false)
  const [groupUsage, setGroupUsage] = useState([0, 0])

  useEffect(updateGroupUsage, [hasRegistered])

  const { standard, short } = getPrice(new Date())

  return (
    <>
      <SEO
        title="Anmälan"
        description="Anmäl dig till Black Friday Trail Run!"
      />
      <Layout>
        <Content>
          {!standard.price ? (
            <RegistrationClosed />
          ) : !hasRegistered ? (
            <>
              <h1>Anmälan</h1>
              <p>
                Anmälan kostar {standard.price} kronor för långa banan till och
                med {standard.cutoffDate}
                {!standard.isLastCutoff
                  ? `, därefter ${standard.nextPrice} kronor.`
                  : " till och me."}
              </p>
              <p>
                Anmälan barn kostar {short.price} kronor till och med{" "}
                {short.cutoffDate}
                {!short.isLastCutoff
                  ? `, därefter ${short.nextPrice} kronor.`
                  : "."}
              </p>
              <p>
                Är ni ett kompis- eller företagsgäng som vill springa? Grupper
                med fem eller fler får 10% rabatt på anmälan till och med sista
                oktober.{" "}
                <a href="mailto:info@blackfridaytrailrun.se">
                  Hör av er till oss
                </a>{" "}
                före anmälan för paketerbjudande.
              </p>
              <Formik
                initialValues={{
                  name: "",
                  club: "",
                  email: "",
                  phone: "",
                  preferredGroup: null,
                  diet1: null,
                  diet2: null,
                }}
                validate={validate}
                onSubmit={onSubmit}
              >
                {({ isSubmitting, errors, values }) => (
                  <Form className={styles.form}>
                    <label htmlFor="name">
                      <span>För- och efternamn</span>
                      <ErrorMessage
                        name="name"
                        component="span"
                        className={styles.error}
                      />
                    </label>
                    <Field
                      id="name"
                      type="text"
                      name="name"
                      placeholder="För- och efternamn"
                    />
                    <label htmlFor="club">
                      <span>Klubb, företag eller bostadsort</span>
                      <ErrorMessage
                        name="club"
                        component="span"
                        className={styles.error}
                      />
                    </label>
                    <Field
                      id="club"
                      type="text"
                      name="club"
                      placeholder="Klubb, företag eller ort"
                    />
                    <label htmlFor="email">
                      <span>E-post</span>
                      <ErrorMessage
                        name="email"
                        component="span"
                        className={styles.error}
                      />
                    </label>
                    <Field
                      id="email"
                      type="email"
                      name="email"
                      placeholder="E-post"
                    />
                    <label htmlFor="phone">
                      <span>Telefonnummer</span>
                      <ErrorMessage
                        name="phone"
                        component="span"
                        className={styles.error}
                      />
                    </label>
                    <Field
                      id="phone"
                      type="phone"
                      name="phone"
                      placeholder="Telefonnummer"
                    />
                    <p>Önskad startgrupp</p>
                    <p>
                      Loppet har tre startgrupper: barngruppen (rekommenderad
                      upp till 13 år) för 2,5 km, och två startgrupper för långa
                      banan: snabba gruppen och lagomgruppen.
                    </p>
                    <p>
                      För att minska trängsel och öka trivsel delar vi in det
                      längre loppet i två startgrupper beroende på uppskattad
                      vana och fart. Avsikten är att du skall få springa med
                      andra som håller ungefär samma fart och att de som
                      springer snabbare slipper tränga sig förbi.
                    </p>
                    <p>
                      OBS! Om du vill tävla om våra priser anmäler du dig till
                      den snabba gruppen!
                    </p>
                    <ErrorMessage
                      name="preferredGroup"
                      component="span"
                      className={styles.error}
                    />
                    {groups.map(([groupName, groupDescription], i) => {
                      const groupNumber = i + 1
                      const isFull = groupUsage[groupNumber] >= groupSize
                      const free = Math.max(
                        0,
                        groupSize - groupUsage[groupNumber]
                      )
                      return (
                        <React.Fragment key={groupName}>
                          <div key={i} className={styles.radio}>
                            <Field
                              id={`group-${groupName}`}
                              type="radio"
                              name="preferredGroup"
                              value={groupNumber.toString()}
                              disabled={isFull}
                            />
                            <label htmlFor={`group-${groupName}`}>
                              {groupName}
                              <p className="text-sm">{groupDescription}</p>
                              {isFull ? (
                                <span className={styles.error}>
                                  Inga lediga platser kvar.
                                </span>
                              ) : groupUsage[groupName] > 120 ? (
                                <span className={styles.error}>
                                  Bara {free} plats{free !== 1 ? "er" : ""}{" "}
                                  kvar!
                                </span>
                              ) : null}
                            </label>
                          </div>
                        </React.Fragment>
                      )
                    })}
                    <p>Kost</p>
                    <p>
                      Efter målgång serveras grillad hamburgare och dryck. Ange
                      typ av hamburgare och bröd som passar dig:
                    </p>
                    <ErrorMessage
                      name="diet1"
                      component="span"
                      className={styles.error}
                    />
                    {[
                      ["meat", "Äter kött"],
                      ["vegan", "Vegetarian / Vegan"],
                    ].map(([id, description]) => (
                      <React.Fragment key={id}>
                        <div className={styles.radio}>
                          <Field
                            id={`diet1-${id}`}
                            type="radio"
                            name="diet1"
                            value={id}
                          />
                          <label htmlFor={`diet1-${id}`}>{description}</label>
                        </div>
                      </React.Fragment>
                    ))}
                    och...
                    <ErrorMessage
                      name="diet2"
                      component="span"
                      className={styles.error}
                    />
                    {[
                      ["gluten", "gluten"],
                      ["no-gluten", "glutenfritt"],
                    ].map(([id, description]) => (
                      <React.Fragment key={id}>
                        <div className={styles.radio}>
                          <Field
                            id={`diet2-${id}`}
                            type="radio"
                            name="diet2"
                            value={id}
                          />
                          <label htmlFor={`diet2-${id}`}>{description}</label>
                        </div>
                      </React.Fragment>
                    ))}
                    {values.preferredGroup ? (
                      <PaymentInfo
                        price={
                          values.preferredGroup === "1"
                            ? short.price
                            : standard.price
                        }
                      />
                    ) : null}
                    {error && (
                      <>
                        <p>
                          <em>Något gick fel! :(</em>
                        </p>
                        <p>
                          Det är inte du, det är vi. Något gick fel med anmälan.
                          Försök gärna igen, och tveka inte att kontakta oss på{" "}
                          <a href="mailto:info@blackfridaytrailrun.se">
                            info@blackfridaytrailrun.se
                          </a>{" "}
                          om problemet kvarstår!
                        </p>
                      </>
                    )}
                    <div className="my-4">
                      {Object.keys(errors).length > 0 && (
                        <p className={styles.error}>
                          Det saknas information i något av fälten ovan, titta
                          igenom formuläret en gång till.
                        </p>
                      )}
                    </div>
                    <Button type="submit" disabled={isSubmitting}>
                      {isSubmitting && (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      )}{" "}
                      Anmäl
                    </Button>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <ThankYou
              price={confirmedPrice}
              onRegisterNew={() => setHasRegistered(false)}
            />
          )}
        </Content>
      </Layout>
    </>
  )

  function onSubmit(values, { setSubmitting }) {
    setError(false)
    const price = values.preferredGroup === "1" ? short.price : standard.price

    window
      .fetch("/api/register", {
        method: "POST",
        body: JSON.stringify({
          data: {
            ...values,
            price,
          },
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(res => {
        setSubmitting(false)
        if (res.ok) {
          setConfirmedPrice(price)
          setHasRegistered(true)
        } else {
          setError(true)
        }
      })
  }

  function updateGroupUsage() {
    window
      .fetch("/api/group-usage")
      .then(response => {
        if (!response.ok) {
          throw new Error(`Unexpected repsonse ${response.status}.`)
        }

        return response.json()
      })
      .then(usage => setGroupUsage(usage))
      .catch(err => {
        console.warn(err)
      })
  }
}

export function validate(props) {
  const errors = {}
  const validators = [
    ["name", validateName],
    ["club", validateClub],
    ["email", validateEmail],
    ["phone", validatePhone],
    ["preferredGroup", validatePreferredGroup],
    ["diet1", d => (!d ? "Ange kött eller vegansk kost" : undefined)],
    ["diet2", d => (!d ? "Ange gluten eller glutenfritt" : undefined)],
  ]

  validators.forEach(([prop, validator]) => {
    const error = validator(props[prop])
    if (error) {
      errors[prop] = error
    }
  })

  return errors
}

export function validateName(name) {
  if (!name) {
    return "Namn är obligatoriskt"
  } else if (!/^\S+\s+\S+/.test(name)) {
    return "Ange både för- och efternamn"
  }
}

export function validateClub(club) {
  if (!club) {
    return "Obligatoriskt"
  }
}

export function validateEmail(email) {
  if (!email) {
    return "E-post är obligatoriskt"
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    return "Ser inte ut som en e-postadress"
  }
}

export function validatePhone(phone) {
  if (!phone) {
    return "Telefonnummer är obligatoriskt"
  } else if (!/[+0-9\-\s]+$/.test(phone)) {
    return "Ser inte ut som ett telefonnummer"
  }
}

export function validatePreferredGroup(group) {
  if (!group) {
    return "Ange önskad startgrupp"
  } else if (isNaN(group) || group < 1 || group > 3) {
    return "Skall vara 1, 2 eller 3."
  }
}

function ThankYou({ price, onRegisterNew }) {
  return (
    <>
      <h1>Tack så mycket!</h1>
      <div className="my-8">
        <PaymentInfo price={price} />
      </div>
      <div className="w-full flex justify-center mt-16">
        <Button onClick={onRegisterNew}>Anmäl en till</Button>
      </div>
    </>
  )
}

function PaymentInfo({ price }) {
  return (
    <>
      <p>
        För att fullfölja anmälan behöver du så klart betala. Betalning görs
        genom:
      </p>
      <ul>
        <li>
          <em>Swish</em> {price} kronor till 123 032 22 06. Märk betalningen{" "}
          <em>Black Friday och ditt namn</em> Mottagare är Tolereds AIK
        </li>
        <li>
          <em>Bankgiro</em> {price} kronor till 5064-2982. Märk betalningen{" "}
          <em>Black Friday och ditt namn</em>. Mottagare är Tolereds AIK
        </li>
      </ul>
      <p>
        Efter att vi registrerat din betalning skickar vi en bekräftelse till
        dig och sätter upp dig på startlistan.
      </p>
      <div className={styles.payment}>
        <span>Att betala:</span>
        <span className={styles.cost}>
          <em>{price}</em> kronor
        </span>
      </div>
    </>
  )
}

export function RegistrationClosed() {
  return (
    <>
      <p>Anmälan öppnar första maj, välkommen tillbaka!</p>
      {/* <p>
        Vi tar emot direktanmälan <em>i mån av plats</em> vid starten, Tolereds
        AIKs klubbstuga, från och med klockan 18:00 den 29 november. Kostnad 500
        kronor.
      </p> */}
    </>
  )
}
