import format from "date-fns/format"
import { prices } from "../config"

export function getPrice(now) {
  const currentDate = format(now, "yyyy-MM-dd")
  const result = {}

  for (const key of Object.keys(prices)) {
    const p = prices[key]
    const index = p.findIndex(({ cutoff }) => currentDate <= cutoff)
    if (index >= 0) {
      const current = p[index]
      result[key] = {
        price: current.price,
        cutoffDate: current.cutoff,
        isLastCutoff: index >= p.length - 1,
        nextPrice: p[index + 1]?.price,
      }
    } else {
      result[key] = {
        price: null,
        cutoffDate: null,
        isLastCutoff: true,
        nextPrice: null,
      }
    }
  }

  return result
}

export function getDateString(now) {
  const pad = d => (d < 10 ? "0" + d : d.toString())
  return `${now.getFullYear()}-${pad(now.getMonth() + 1)}-${pad(now.getDate())}`
}
